<template>
  <b-row>
    <b-col
      cols="12"
      lg="12"
    >
      <b-card>
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Address 1"
                rules="required"
              >
                <b-form-group
                  label="Address 1"
                  label-for="CC"
                >
                  <b-form-input
                    id="CC"
                    v-model="addressForm.address_1"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Address 1"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Address 2"
                label-for="CC"
              >
                <b-form-input
                  id="CC"
                  v-model="addressForm.address_2"
                  trim
                  placeholder="Address 2"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Country"
                label-for="country"
              >
                <v-select
                  id="country"
                  v-model="addressForm.country_id"
                  label="name_en"
                  :reduce="country => country.id"
                  :options="countries"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="States"
                label-for="states"
              >
                <v-select
                  id="states"
                  v-model="addressForm.state_id"
                  label="name"
                  :reduce="states => states.id"
                  :options="states"
                  @input="selectCity($event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="City"
                label-for="city"
              >
                <v-select
                  id="city"
                  v-model="cityName"
                  label="name"
                  :reduce="city => city"
                  :options="filterCity"
                  @input="setCity($event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Area"
                label-for="city"
              >
                <v-select
                  id="city"
                  v-model="addressForm.area_id"
                  label="name"
                  :reduce="area => area.id"
                  :options="areas"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >

              <b-form-group
                label="Building Number"
                label-for="building"
              >
                <b-form-input
                  id="building"
                  v-model="addressForm.building_no"
                  placeholder="Building Number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Floor Number"
                rules="required"
              >
                <b-form-group
                  label="Floor Number"
                  label-for="floor"
                >
                  <b-form-input
                    id="floor"
                    v-model="addressForm.floor_no"
                    :state="getValidationState(validationContext)"
                    placeholder="Floor Number"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >

              <b-form-group
                label="Flat Number"
                label-for="flat"
              >
                <b-form-input
                  id="flat"
                  v-model="addressForm.flat_no"
                  placeholder="Flat Number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Set Default Address"
                label-for="flat"
              >
                <b-form-checkbox
                  v-model="addressForm.is_default"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <GmapMap
                ref="locmap"
                map-style-id="roadmap"
                :center="center"
                :zoom="20"
                map-type-id="terrain"
                style="width: 100%; height: 50vmin"
              >
                <GmapMarker
                  :position="center"
                  :clickable="true"
                />
              </GmapMap>
            </b-col>
          </b-row>
        </validation-observer>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            @click="addAddress()"
          >
            Save
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import { ref, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import axios from 'axios'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    vSelect,
  },
  props: {
    returnAfterSubmit: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { getValidationState } = formValidation()
    const { router } = useRouter()
    const addressForm = ref({
      addressable_id: router.currentRoute.params.id,
      address_1: '',
      address_2: '',
      country_id: '',
      state_id: '',
      city_id: '',
      area_id: '',
      building_no: '',
      floor_no: '',
      flat_no: '',
      latitude: '',
      longitude: '',
      is_default: false,
    })

    // eslint-disable-next-line prefer-const
    let center = ref({})

    const filterCity = ref([])
    const loader = ref(false)
    // eslint-disable-next-line camelcase
    const cityName = ref('')

    //* Get and Set Countries
    const getCountries = () => store.dispatch('places/getCountries')
    getCountries()
    const countries = computed(() => store.state.places.countries)

    //* Get and Set States
    const getStates = () => store.dispatch('places/getStates')
    getStates()
    const states = computed(() => store.state.places.states)

    //* Get and Set Cities
    const getCities = () => store.dispatch('places/getCities')
    getCities()
    const cities = computed(() => store.state.places.cities)

    //* Get and Set Cities
    const getAreas = () => store.dispatch('places/getAreas')
    getAreas()
    const areas = computed(() => store.state.places.areas)

    return {
      getValidationState,
      addressForm,
      center,
      required,
      countries,
      states,
      areas,
      cities,
      loader,
      router,
      filterCity,
      cityName,
    }
  },
  mounted() {
    this.geolocation()
    this.viewAddress()
  },
  methods: {
    geolocation() {
      navigator.geolocation.getCurrentPosition(position => {
        const newPostion = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        this.center = newPostion
        this.addressForm.latitude = position.coords.latitude
        this.addressForm.longitude = position.coords.longitude
      })
    },
    setCity(data) {
      this.addressForm.city_id = data.id
    },
    selectCity(id) {
      // eslint-disable-next-line eqeqeq
      const filteredCities = this.cities.filter(city => city.state_id == id)
      this.filterCity = filteredCities
    },
    addAddress() {
      this.loader = true
      if (this.router.currentRoute.params.addressid) {
        this.setAddressableType()
        axios.put(`addresses/${this.router.currentRoute.params.addressid}`, this.addressForm).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.returnToPageAfterSubmit()
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            this.loader = true
            this.setAddressableType()
            axios.post('addresses', this.addressForm).then(res => {
              if (res.status === 200 || res.status === 201) {
                this.returnToPageAfterSubmit()
              }
            }).finally(() => {
              this.loader = false
            })
          }
        })
      }
    },
    viewAddress() {
      if (this.router.currentRoute.params.addressid) {
        axios.get(`addresses/${this.router.currentRoute.params.addressid}`).then(res => {
          if (res.status === 200) {
            this.addressForm = res.data?.data
            this.addressForm.addressable_type = 'users'
            this.cityName = res.data?.data.city_name
            if (this.addressForm.is_default === 1) {
              this.addressForm.is_default = true
            } else {
              this.addressForm.is_default = false
            }
            const stateId = res.data?.data?.state_id
            this.selectCity(stateId)
          }
        })
      }
    },
    setAddressableType() {
      if (this.router.currentRoute.path.includes('users/admin/') || this.router.currentRoute.path.includes('users/merchant/') || this.router.currentRoute.path.includes('users/customer/')) {
        this.addressForm.addressable_type = 'users'
      } else if (this.router.currentRoute.name === 'show-merchant-add-address' || this.router.currentRoute.name === 'show-merchant-edit-address') {
        this.addressForm.addressable_type = 'merchants'
      } else {
        this.addressForm.addressable_type = 'branches'
      }
    },
    returnToPageAfterSubmit() {
      if (this.router.currentRoute.path.includes('users/admin')) {
        this.router.push({ name: 'show-user', params: { id: this.router.currentRoute.params.id } })
      } else if (this.router.currentRoute.path.includes('users/merchant')) {
        this.router.push({ name: 'show-user-merchant', params: { id: this.router.currentRoute.params.id } })
      } else if (this.router.currentRoute.path.includes('users/customer')) {
        this.router.push({ name: 'show-user-customer', params: { id: this.router.currentRoute.params.id } })
      } else if (this.router.currentRoute.name === 'show-merchant-add-address' || this.router.currentRoute.name === 'show-merchant-edit-address') {
        this.router.push({ name: 'show-merchant', params: { id: this.router.currentRoute.params.id } })
      } else {
        this.router.push({ name: 'show-branche', params: { id: this.router.currentRoute.params.id } })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
